"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Share-Merits
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreatorInfoToJSON = exports.CreatorInfoFromJSONTyped = exports.CreatorInfoFromJSON = exports.instanceOfCreatorInfo = void 0;
var runtime_1 = require("../runtime");
var ContainerField_1 = require("./ContainerField");
/**
 * Check if a given object implements the CreatorInfo interface.
 */
function instanceOfCreatorInfo(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "firstName" in value;
    isInstance = isInstance && "lastName" in value;
    return isInstance;
}
exports.instanceOfCreatorInfo = instanceOfCreatorInfo;
function CreatorInfoFromJSON(json) {
    return CreatorInfoFromJSONTyped(json, false);
}
exports.CreatorInfoFromJSON = CreatorInfoFromJSON;
function CreatorInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'additionalInfo': !(0, runtime_1.exists)(json, 'additionalInfo') ? undefined : (json['additionalInfo'].map(ContainerField_1.ContainerFieldFromJSON)),
    };
}
exports.CreatorInfoFromJSONTyped = CreatorInfoFromJSONTyped;
function CreatorInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'additionalInfo': value.additionalInfo === undefined ? undefined : (value.additionalInfo.map(ContainerField_1.ContainerFieldToJSON)),
    };
}
exports.CreatorInfoToJSON = CreatorInfoToJSON;
